import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { AppBar } from 'shared-lib';
import BottomAppBar from './BottomAppBar';

const Layout: React.FC = () => {
  const location = useLocation();

  const getPageInfo = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/':
        return { title: 'Календарь', showBackButton: false };
      case '/clients':
        return { title: 'Клиенты', showBackButton: false };
      case '/settings':
        return { title: 'Настройки', showBackButton: false };
      case '/offices':
        return { title: 'Мои помещения', showBackButton: true, backTo: '/settings' };
      default:
        if (pathname.startsWith('/offices/') && pathname.endsWith('/schedule')) {
          return { title: 'Расписание офиса', showBackButton: true, backTo: '/offices' };
        }
        if (pathname.startsWith('/offices/') && pathname.endsWith('/edit')) {
          return { title: 'Редактирование помещения', showBackButton: true, backTo: '/offices' };
        }
        return { title: 'Админ-панель', showBackButton: false };
    }
  };

  return (
    <>
      <AppBar getPageInfo={getPageInfo} />
      <Box sx={{ pb: 7 }}>
        <Outlet />
      </Box>
      <BottomAppBar />
    </>
  );
};

export default Layout;
