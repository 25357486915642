import React from 'react';
import { Typography, Container } from '@mui/material';

const Calendar: React.FC = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Календарь
      </Typography>
      {/* Add calendar component here */}
    </Container>
  );
};

export default Calendar;