import React from 'react';
import { GoogleCalendarConnector, SettingsContainer, SettingsNavigationItem } from 'shared-lib';

const Settings: React.FC = () => {
  return (
    <SettingsContainer title="Настройки">
      <SettingsNavigationItem
        to="/offices"
        primary="Помещения"
        secondary="Управление помещениями, их расписанием и календарями"
      />
      <GoogleCalendarConnector
        description="Подключенный аккаунт Google Calendar будет использоваться для назначения календарей помещениям, где вы являетесь владельцем."
      />
    </SettingsContainer>
  );
};

export default Settings;
